import { useTranslation } from 'next-i18next'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import pathConfiguration from 'src/configuration/path'
import ApplicationFormModal from '~/components/Apply/[jobId]/components/ApplicationFormModal'
import TooltipWithButton from '~/components/Apply/[jobId]/components/TooltipWithButton'
import ShareJobModal from '~/components/Jobs/ShareJobModal'
import { PUBLIC_APP_URL } from '~/core/constants/env'
import { CAREERS_LIST_URL } from '~/core/constants/url'
import { IFormAction } from '~/core/ui/Form'
import { TextButton } from '~/core/ui/TextButton'
import { Tooltip } from '~/core/ui/Tooltip'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { defaultFormatDate } from '~/core/utilities/format-date'
import schemaInternalApplicationForm from '~/lib/features/apply/jobId/schema/validation-internal-application-form'
import { ApplicationFormType } from '~/lib/features/apply/jobId/types'
import useShareJobLogic from '~/lib/features/jobs/hooks/use-share-job-logic'
import { JOB_STATUS_ENUM } from '~/lib/features/jobs/utilities/enum'
import CreateInternalApplicationMutation from '~/lib/features/referrals/graphql/create-internal-application-mutation'
import { mappingProfileEmployeeToApplicationForm } from '~/lib/features/referrals/mapping/mappingApplicationForm'
import {
  EmployeeProfileType,
  JobDetailType,
  ReferralFormType
} from '~/lib/features/referrals/types'
import { ReferralSettingType } from '~/lib/features/settings/referrals/types'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'

const ActionOpenJobInternal = ({
  job,
  refetch,
  dataReferral,
  setReferralJob,
  setOpenReferralModal,
  fetchEmployeeProfile,
  employeeProfile
}: {
  job: JobDetailType
  refetch?: any
  setReferralJob: Dispatch<SetStateAction<ReferralFormType | undefined>>
  dataReferral: ReferralSettingType | undefined
  setOpenReferralModal: Dispatch<SetStateAction<boolean>>
  employeeProfile: EmployeeProfileType | undefined
  fetchEmployeeProfile: () => void
}) => {
  const { t } = useTranslation()
  const { user } = useBoundStore()
  const [openShareModal, setOpenShareModal] = useState<boolean>(false)
  const [openApplicant, setOpenApplicant] = useState<boolean>(false)
  const { valueShareJobWithCondition } = useShareJobLogic()
  const onOpenApplicationChange = useCallback((state: boolean) => {
    setOpenApplicant(state)
  }, [])

  const handleOpenReferralModal = useCallback(
    (job: JobDetailType) => {
      setReferralJob({
        jobId: [
          {
            value: String(job?.id),
            supportingObj: {
              name: job?.title
            }
          }
        ]
      })
      setOpenReferralModal(true)
    },
    [setOpenReferralModal]
  )
  const onClickApplyNow = useCallback(() => {
    onOpenApplicationChange(true)
  }, [onOpenApplicationChange])
  const checkConditionShareJob = valueShareJobWithCondition({
    enablingReferral: dataReferral?.values?.enabling || false,
    enablingCareerSite:
      user.currentTenant?.careerSiteSettings?.enablingCareerSiteSetting ||
      false,
    enableJobReferral: job?.jobReferable || false,
    jobStatus: job?.status || ''
  })
  const careerSiteSettingsLanguages =
    user?.currentTenant?.careerSiteSettings?.languages
  const japaneseLanguageDefault = careerSiteSettingsLanguages?.ja?.default
  const referralPortal = dataReferral?.values?.referral_portal
  const setToast = useBoundStore((state) => state.setToast)

  const { trigger, isLoading } = useSubmitCommon(
    CreateInternalApplicationMutation
  )
  const onFinish = useCallback(
    async (data: ApplicationFormType, formAction: IFormAction) => {
      if (isLoading) {
        return
      }

      trigger({
        jobId: Number(job.id),
        fullName: data.fullName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        resumeFile: data?.resumeFile?.[0],
        ...(data.headline ? { headline: data.headline.trim() } : {}),
        ...(data.coverLetter ? { coverLetter: data.coverLetter } : {}),
        ...(!!data.termsAndConditions ? { termsAndConditions: true } : {})
      }).then((result) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            formAction,
            setToast,
            callbackHandleStatusError422: (keys) => {
              keys.forEach((key) => {
                if (key.message.includes('terms_and_conditions')) {
                  setToast({
                    open: true,
                    type: 'error',
                    title: `${t('form:consent_data_processing')}`
                  })
                } else {
                  if (!key.field) {
                    setToast({
                      open: true,
                      type: 'error',
                      title: key.message
                    })
                  } else {
                    formAction.setError(key.field, {
                      type: 'custom',
                      message: key.message
                    })
                  }
                }
              })
            }
          })
        }
        const { internalApplicantsCreate } = result.data
        if (internalApplicantsCreate?.profile?.id) {
          //setToast
          setToast({
            open: true,
            type: 'success',
            title: `${t('careers:applied:applied_successfully')}`
          })

          setOpenApplicant(false)
          fetchEmployeeProfile()
          refetch()
        }
        return true
      })
    },
    [isLoading, job?.id, job?.tenant?.slug]
  )

  return (
    <div className="text-center">
      <div className="flex justify-start">
        {(referralPortal?.referral_only || referralPortal?.referral_job) && (
          <div className="flex">
            {job?.publicReferralUri ||
            checkConditionShareJob?.shareInternal ||
            checkConditionShareJob?.sharePublic ? (
              <div>
                <Tooltip
                  content={`${t(
                    'referrals:open_jobs:share:disabled_button_tooltip'
                  )}`}
                  classNameConfig={{
                    content:
                      job?.status !== JOB_STATUS_ENUM.publish ? '' : 'hidden'
                  }}>
                  <TextButton
                    label={t('label:share')}
                    size="md"
                    isDisabled={job?.status !== JOB_STATUS_ENUM.publish}
                    underline={false}
                    onClick={() => {
                      setOpenShareModal(true)
                    }}
                  />
                </Tooltip>
              </div>
            ) : null}

            <div className="ml-4">
              <TextButton
                label={t('label:refer')}
                size="md"
                underline={false}
                onClick={() => {
                  handleOpenReferralModal(job)
                }}
              />
            </div>
          </div>
        )}
        {(referralPortal?.job_only || referralPortal?.referral_job) && (
          <div className="ml-4">
            <TooltipWithButton
              content={t('referrals:tooltip:applied_tooltip_disable', {
                date: job?.currentUserAppliedAt
                  ? `${defaultFormatDate(new Date(job.currentUserAppliedAt))}`
                  : ''
              })}
              isShowTooltip={!!job.currentUserAppliedAt}>
              <TextButton
                isDisabled={!!job.currentUserAppliedAt}
                label={t(
                  !!job.currentUserAppliedAt
                    ? 'referrals:detail:btn_applied'
                    : 'referrals:detail:btn_apply'
                )}
                size="md"
                underline={false}
                onClick={() => onClickApplyNow()}
              />
            </TooltipWithButton>
          </div>
        )}
      </div>
      <ShareJobModal
        shareInternal={checkConditionShareJob?.shareInternal}
        sharePublic={checkConditionShareJob?.sharePublic}
        open={openShareModal}
        setOpen={setOpenShareModal}
        urlReferral={`${PUBLIC_APP_URL}${pathConfiguration.referral.jobDetail({
          jobId: job?.id.toString()
        })}`}
        url={`${PUBLIC_APP_URL}${
          japaneseLanguageDefault ? '/ja' : ''
        }${CAREERS_LIST_URL}/${
          job?.publicReferralUri
        }&utm_medium=internal_social_share`}
      />

      {/* {openApplicant && ( */}
      <ApplicationFormModal
        job={job}
        open={openApplicant}
        setOpen={onOpenApplicationChange}
        isSubmitting={isLoading}
        onSubmit={onFinish}
        disableFields={['email']}
        defaultValue={mappingProfileEmployeeToApplicationForm({
          profile: employeeProfile,
          user
        })}
        schema={schemaInternalApplicationForm(t)}
      />
      {/* )} */}
    </div>
  )
}

export default ActionOpenJobInternal
