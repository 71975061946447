import React, { useEffect } from 'react'
import { TablePagination } from '~/core/ui/TablePagination'
import { TypographyText } from '~/core/ui/Text'
import { CellContext, HeaderContext } from '@tanstack/react-table'
import { Tooltip } from '~/core/ui/Tooltip'

import pathConfiguration from 'src/configuration/path'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { Badge, IColorBadgeType } from '~/core/ui/Badge'
import { defaultFormatDate } from '~/core/utilities/format-date'
import { JobDetailType, MyReferralType } from '~/lib/features/referrals/types'

import useMyReferralsDashboard from '~/lib/features/dashboard/hooks/use-my-referrals-dashboard'
import { Avatar } from '~/core/ui/Avatar'

const MyReferralsView = ({
  setCountReferrals
}: {
  setCountReferrals: React.Dispatch<React.SetStateAction<number | undefined>>
}) => {
  const { t } = useTranslation()
  const { data, fetchNextPage, fetchPreviousPage, isFetching } =
    useMyReferralsDashboard()

  const referralStatus = (referral?: MyReferralType) => {
    const lowercaseStatus = referral?.status
      .trim()
      .toLowerCase()
      .replaceAll(' ', '_')
    if (lowercaseStatus === 'not_hired')
      return { label: `${t('referrals:my_referrals:notHired')}`, color: 'red' }

    if (lowercaseStatus === 'hired')
      return { label: `${t('referrals:my_referrals:hired')}`, color: 'green' }

    return {
      label: `${t('referrals:my_referrals:inProcess')}`,
      color: 'yellow'
    }
  }

  useEffect(() => {
    if (data?.meta?.totalRowCount) {
      setCountReferrals(data?.meta?.totalRowCount)
    }
  }, [data, setCountReferrals])

  return (
    <>
      <div className="ml-4 mt-3 flex h-7 flex-row space-x-3">
        <div className="flex flex-row items-center space-x-2 rounded-md bg-gray-50 px-4">
          <TypographyText className="text-xl font-medium text-gray-900">
            {data?.meta?.extras?.not_hired_count}
          </TypographyText>
          <div className="flex">
            <Badge
              color="red"
              type="dotLeading"
              variant="outline"
              className="border-0 px-0">
              {t('referrals:my_referrals:notHired')}
            </Badge>
          </div>
        </div>
        <div className="flex flex-row items-center space-x-2 rounded-md bg-gray-50 px-4">
          <TypographyText className="text-xl font-medium text-gray-900">
            {data?.meta?.extras?.in_process_count}
          </TypographyText>
          <div className="flex">
            <Badge
              color="yellow"
              type="dotLeading"
              variant="outline"
              className="border-0 px-0">
              {t('referrals:my_referrals:inProcess')}
            </Badge>
          </div>
        </div>
        <div className="flex flex-row items-center space-x-2 rounded-md bg-gray-50 px-4">
          <TypographyText className="text-xl font-medium text-gray-900">
            {data?.meta?.extras?.hired_count}
          </TypographyText>
          <div className="flex">
            <Badge
              color="green"
              type="dotLeading"
              variant="outline"
              className="border-0 px-0">
              {t('referrals:my_referrals:hired')}
            </Badge>
          </div>
        </div>
      </div>
      <TablePagination
        textOverride={{
          of: `${t('label:of')}`
        }}
        emptyConfig={{
          classNameEmpty: 'flex items-center h-auto justify-center h-full',
          title: `${t('dashboard:internal:referrals:empty:title')}`,
          titleSearch: undefined
        }}
        tableConfig={{
          defaultPageSize: 8
        }}
        dataQuery={{
          isFetching,
          fetcher: {
            fetchNextPage,
            fetchPreviousPage
          },
          data
        }}
        columns={[
          {
            accessorKey: 'jobTitle',
            header: () => (
              <TypographyText className="text-left text-xs font-normal text-gray-700 dark:text-gray-300">
                {t('dashboard:internal:referrals:candidate')}
              </TypographyText>
            ),
            cell: (info: CellContext<MyReferralType, undefined>) => {
              const referral = info?.row?.original
              return (
                <div className="flex w-full items-center space-x-1.5">
                  <div className="mr-2">
                    <Avatar
                      size="md"
                      alt={referral?.profile?.fullName}
                      src={referral?.profile?.avatarVariants?.thumb?.url}
                      color="#FFFFFF"
                    />
                  </div>
                  <Tooltip content={referral?.profile?.fullName} align="start">
                    <TypographyText className="line-clamp-1 break-all text-sm font-medium text-gray-900">
                      {referral?.profile?.fullName}
                    </TypographyText>
                  </Tooltip>
                </div>
              )
            },
            footer: (props: HeaderContext<MyReferralType, undefined>) =>
              props.column.id,
            size: 264
          },
          {
            accessorKey: 'job',
            header: () => (
              <TypographyText className="text-left text-xs font-normal text-gray-700 dark:text-gray-300">
                {t('dashboard:internal:referrals:job')}
              </TypographyText>
            ),
            cell: (info: CellContext<MyReferralType, undefined>) => {
              const referral = info?.row?.original
              return (
                <div className="flex items-center space-x-1.5">
                  <TypographyText className="whitespace-nowrap text-sm font-normal text-gray-900">
                    <Link
                      href={pathConfiguration?.referral?.jobDetail({
                        jobId: referral?.job.id
                      })}>
                      <Tooltip
                        content={referral?.job?.title}
                        classNameAsChild="inline hover:underline">
                        {referral?.job?.title}
                      </Tooltip>
                    </Link>
                  </TypographyText>
                </div>
              )
            },
            footer: (props: HeaderContext<MyReferralType, undefined>) =>
              props.column.id,
            size: 264
          },
          {
            accessorKey: 'stage',
            header: () => (
              <TypographyText className="text-center text-xs font-normal text-gray-700 dark:text-gray-300">
                {t('dashboard:internal:referrals:stage')}
              </TypographyText>
            ),
            cell: (info: CellContext<MyReferralType, undefined>) => {
              const referral = info?.row?.original
              const currentStage = referralStatus(referral)
              return (
                <div className="flex justify-center">
                  <Badge
                    color={currentStage.color as IColorBadgeType}
                    type="dotLeading"
                    variant="outline"
                    radius="circular">
                    {currentStage?.label}
                  </Badge>
                </div>
              )
            },
            footer: (props: HeaderContext<MyReferralType, undefined>) =>
              props.column.id,
            size: 135
          },
          {
            accessorKey: 'currentStagedDate',
            header: () => (
              <TypographyText className="text-center text-xs font-normal text-gray-600 dark:text-gray-300">
                {t('dashboard:internal:referrals:referralDate')}
              </TypographyText>
            ),
            cell: (info: CellContext<MyReferralType, undefined>) => {
              const referral = info?.row?.original

              return (
                <TypographyText className="mt-1 text-center text-xs text-gray-600">
                  {defaultFormatDate(
                    new Date(referral.applicant.currentStagedDate)
                  )}
                </TypographyText>
              )
            },
            footer: (props: HeaderContext<JobDetailType, undefined>) =>
              props.column.id,
            size: 135
          }
        ]}
        classNameTable={'h-[calc(100%_-_130px)] mt-2 px-4'}
        classNamePaginationWrapper={
          (data?.data || [])?.length > 0 ? 'pb-0 mb-0 pr-1' : ''
        }
        isHeaderSticky
      />
    </>
  )
}

export default MyReferralsView
