import React, { useCallback, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Tabs, TabsList, TabsTrigger, TabsTriggerView } from '~/core/ui/Tabs'
import { TextButton } from '~/core/ui/TextButton'
import MyJobInternalView from './MyJobInternalView'
import MyReferralsView from './MyReferralsView'
import configuration from '~/configuration'
import Link from 'next/link'
import useReferralSetting from '~/lib/features/settings/referrals/hooks/useReferralSetting'
import useProfileEmployeeHook from '~/lib/features/referrals/hooks/use-profile-employee-hook'

const TAB_KEYS = [{ value: 'jobs' }, { value: 'referrals' }]

const JobInternalReferralsContainer = () => {
  const { t } = useTranslation()
  const { dataReferral } = useReferralSetting()
  const referralPortal = dataReferral?.values?.referral_portal
  const { employeeProfile, fetchEmployeeProfile } = useProfileEmployeeHook()

  const [tab, setTab] = useState<string>(TAB_KEYS[0].value)
  const [countReferrals, setCountReferrals] = useState<number>()
  const [countOpenJobs, setCountOpenJobs] = useState<number>()
  const onChangeTab = useCallback((value: string) => {
    setTab(value)
  }, [])

  const renderedTabs = () => {
    return (
      <div className="mx-4 mt-3 flex items-start justify-between border-b border-b-gray-100">
        <Tabs
          defaultValue={tab || TAB_KEYS[0].value}
          onValueChange={onChangeTab}>
          <TabsList size="sm">
            {TAB_KEYS.filter((tab) => {
              if (!referralPortal?.job_only) return true
              return tab.value !== 'referrals'
            }).map((tab, index) => (
              <TabsTrigger
                classNameButton="pb-2.5"
                key={`tab-${index}`}
                size="sm"
                value={tab.value}>
                <TabsTriggerView
                  size="sm"
                  session={{
                    count: index === 0 ? countOpenJobs : countReferrals,
                    value: tab.value,
                    label:
                      index === 0
                        ? `${t(`dashboard:internal:tabs:jobInternal`)}`
                        : `${t(`dashboard:internal:tabs:myReferrals`)}`
                  }}
                />
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
        <Link href={configuration.path.referral.list}>
          <TextButton underline={false}>{t(`button:viewAll`)}</TextButton>
        </Link>
      </div>
    )
  }

  const renderContent = () => {
    if (tab === TAB_KEYS[0].value) {
      return (
        <MyJobInternalView
          fetchEmployeeProfile={fetchEmployeeProfile}
          employeeProfile={employeeProfile}
          dataReferral={dataReferral}
          setCountOpenJobs={setCountOpenJobs}
        />
      )
    }
    return <MyReferralsView setCountReferrals={setCountReferrals} />
  }

  return (
    <div className="relative h-full">
      {renderedTabs()}
      {renderContent()}
    </div>
  )
}

export default JobInternalReferralsContainer
